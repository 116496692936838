<template>
  <div class="page-class page-user-list">
    <h1 class="page-title text-center">{{ title }}</h1>
    <feedback-list-box
      :columns="columns"
      :rows="videos"
      :baseUrl="baseUrl"
      :loading="loading"
      :showAdd="true"
      :showToolbar="true"
      :showPagination="true"
      :pagination="pagination"
      :filterOptions="filterOptions"
      :steps="steps"
      :callbacks="callbacks"
      :showFilter="true"
      :searchItems="searchItems"
      @delete="onDelete"
      addText="Upload New Video"
      emptyText="No Video found. Please upload a video."
      @add="onAdd"
      @edit="onEdit"
    >
      <template v-slot:emailAddressFormatted="slotProps">
        <span class="span-text span-block">
          {{ slotProps.row.email }}
        </span>
        <template v-if="slotProps.row.confirmedAtFormatted">
          <span class="text-success span-help-text" :title="slotProps.row.confirmedAt">
            ({{ slotProps.row.confirmedAtFormatted }})
          </span>
        </template>
        <template v-if="slotProps.row.confirmedAtFormatted === null">
          <span class="text-warning span-help-text"> (Not confirmed) </span>
        </template>
      </template>
      <template v-slot:lastLoginAtFormatted="slotProps">
        <template v-if="slotProps.row.lastLoginAtFormatted">
          <span class="text-success">{{ slotProps.row.lastLoginAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.lastLoginAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
      <template v-slot:blockedAtFormatted="slotProps">
        <template v-if="slotProps.row.blockedAtFormatted">
          <span class="text-success">{{ slotProps.row.blockedAtFormatted }}</span>
        </template>
        <template v-if="slotProps.row.blockedAtFormatted == null">
          <span class="text-warning">Never logged in</span>
        </template>
      </template>
    </feedback-list-box>
  </div>
</template>

<script>
import moment from 'moment';
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
// import ChecklistBox from '@/components/ChecklistBox.vue';
import FeedbackListBox from '../../components/FeedbackListBox.vue';
import router from '@/router';
import _ from 'lodash';

export default {
  name: 'VideoList',
  components: { FeedbackListBox },
  metaInfo() {
    return {
      title: 'Videos List',
      meta: [
        {
          name: 'description',
          content: `Manage Videos List`
        }
      ]
    };
  },
  mounted() {
    // if (window.localStorage.getItem('hasCompletedChecklist') !== 'true') {
    //   this.$tours.myTour.start();
    // }
    if (_.isEmpty(this.$route.query)) {
      this.listVideos({
        query: {
          ...this.$route.query,
          startdate: moment().subtract(1, 'month').format('YYYY-MM-DD'),
          enddate: moment().format('YYYY-MM-DD')
        }
      });
    } else {
      this.listVideos({ query: this.$route.query });
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.listVideos({ query: to.query });
    next();
  },
  data() {
    return {
      title: 'Videos List',
      callbacks: {
        onFinish() {
          window.localStorage.setItem('hasCompletedChecklist', 'true');
        }
      },
      steps: [
        {
          target: '[data-v-step="0"]', // We're using document.querySelector() under the hood
          content: `Enter search criteria based on Name or Store Id to Filter the List of Checklist.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="1"]',
          content: 'Select Filter to further refine search Results.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="2"]',
          content: 'Select Date Range to further refine search results and click Apply button.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="3"]', // We're using document.querySelector() under the hood
          content: `Click to create a new Checklist.`,
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="4"]',
          content: 'Click to download Checklist in CSV format .',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="5"]',
          content: 'Click to View/Edit Checklist.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        },
        {
          target: '[data-v-step="6"]',
          content: 'Click to Download Checklist in MIS format.',
          params: {
            placement: 'bottom',
            enableScrolling: false
          }
        }
      ],
      columns: [
        {
          type: 'row_num',
          headerText: '#',
          class: { ' text-center': true },
          textKey: 'rowNum',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Title',
          class: { 'text-center': true },
          textKey: 'title',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Video Url',
          class: { 'text-center': true },
          urlKey: 'videoUrl',
          width: '7%'
        },
        {
          type: 'string',
          headerText: 'Video Description',
          class: { 'text-center': true },
          textKey: 'description',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Active',
          class: { 'text-center': true },
          textKey: 'active',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Start Date',
          class: { 'text-center': true },
          textKey: 'startDate',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'End Date',
          class: { 'text-center': true },
          textKey: 'endDate',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Region',
          class: { 'text-center': true },
          textKey: 'regions',
          width: '8%'
        },
        {
          type: 'string',
          headerText: 'Type',
          class: { 'text-center': true },
          textKey: 'type',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Cluster',
          class: { 'text-center': true },
          textKey: 'clusters',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Formats',
          class: { 'text-center': true },
          textKey: 'formats',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Store Id',
          class: { 'text-center': true },
          textKey: 'storeids',
          width: '5%'
        },
        {
          type: 'string',
          headerText: 'Watched By',
          class: { 'text-center': true },
          textKey: 'watchBy',
          width: '4%'
        },
        {
          type: 'string',
          headerText: 'Uploaded By',
          class: { 'text-center': true },
          textKey: 'uploadedByName',
          width: '7%'
        },

        {
          type: 'string',
          headerText: 'Uploaded At',
          class: { 'text-center': true },
          textKey: 'createdAt',
          width: '7%'
        },
        {
          type: 'functions',
          headerText: 'Functions',
          class: { 'text-center': true },
          width: '7%',
          functions: {
            edit: false,
            delete: true
          }
        }
      ],
      searchItems: ['Video Title'],
      filterOptions: [
        { value: null, text: 'Select Filter' },
        { value: 'Active-True', text: 'Active-True' },
        { value: 'Active-False', text: 'Active-False' }
      ]
    };
  },
  computed: {
    ...mapState('video', ['loading', 'baseUrl', 'videos', 'pagination']),
    ...mapState('darkMode', ['isDarkMode'])
  },
  methods: {
    ...mapActions('video', ['listVideos', 'deleteOne']),
    onAdd() {
      router.push('/video/new');
    },
    onEdit({ row }) {
      router.push(`/video/${row.id}`);
    },
    onDelete({ row }) {
      Vue.swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this.",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
        background: this.isDarkMode ? '#464d54' : '#ffffff',
        customClass: {
          title: this.isDarkMode ? 'text-light' : 'text-dark',
          content: this.isDarkMode ? 'text-light' : 'text-dark'
        },
        preConfirm: () => {
          this.deleteOne({
            id: row.id
          });
        }
      });
    }
  }
};
</script>
