<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';

export default {
  name: 'VideoRedirect',
  async mounted() {
    if (this.$route.params.hasOwnProperty('id')) {
      const { id } = this.$route.params;
      this.getVideo({ id });
    } else {
      this.title = 'No Id Provieded for Video';
    }
  },
  data() {
    return {
      title: 'Redirecting...'
    };
  },
  computed: {
    ...mapState('video', ['video'])
  },
  methods: {
    ...mapActions('video', ['getOne']),
    getVideo({ id }) {
      this.getOne({
        id,
        router
      });
    }
  },
  watch: {
    video(newValue) {
      if (newValue.videoUrl) {
        window.location.href = newValue.videoUrl;
      } else {
        this.title = 'Video Not Found';
      }
    }
  }
};
</script>
