<template>
  <div class="page-class page-center">
    <div class="page-staff-form">
      <h1 class="page-title text-center">{{ title }}</h1>
      <video-form-box list-url="/offers" :id="id" video-type="video" :form-type="formType" @add="onAdd">
      </video-form-box>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import router from '@/router';
import VideoFormBox from '../../components/VideoFormBox.vue';

export default {
  name: 'VideoForm',
  components: {
    VideoFormBox
  },
  async mounted() {
    if (this.$route.name === 'video-new') {
      this.formType = 'new';
      this.id = null;
    } else {
      this.formType = 'update';
      this.id = this.$route.params.id;

      await this.getOne({
        type: 'video',
        id: this.id,
        router
      });
    }
  },
  data() {
    return {
      formType: '',
      id: null
    };
  },
  computed: {
    title() {
      return this.formType === 'new' ? 'Upload New Video' : 'Update Video';
    },
    ...mapState('video', ['loading'])
  },
  methods: {
    ...mapActions('video', ['getOne', 'postOne', 'patchOne']),
    onAdd({ video }) {
      this.postOne({
        video,
        router,
        redirectUrl: '/video'
      });
    },
    onEdit({ video }) {
      this.patchOne({
        type: 'video',
        id: this.id,
        video,
        router,
        redirectUrl: '/video'
      });
    }
  }
};
</script>
